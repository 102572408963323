import _ from 'lodash'

export const roleMap = {
    // 管理员
    'admin': {
        key: 'admin',
        display: '管理员',
        menus: ['home', 'course', 'notification', 'question', 'parents', 'teachers', 'works', 'professor', 'user']
    },
    // 高级用户
    'expert': {
        key: 'expert',
        display: '高级用户',
        menus: ['home', 'course', 'notification', 'question', 'parents', 'teachers', 'works']
    },
}

export const authRoleMap = {
    Admin: 'admin',
    AdvancedUser: 'expert',
}

export const roleAuth = {
    // 首页
    home: {
        menuShow: [authRoleMap['Admin'], authRoleMap['AdvancedUser']]
    },
    // 家长课堂管理
    parentCourse: {
        menuShow: [authRoleMap['Admin'], authRoleMap['AdvancedUser']]
    },
    // 教师课堂管理
    teacherCourse: {
        menuShow: [authRoleMap['Admin'], authRoleMap['AdvancedUser']]
    },
    // 答疑管理
    qaList: {
        menuShow: [authRoleMap['Admin'], authRoleMap['AdvancedUser']]
    },
    // 通知管理
    notificationList: {
        menuShow: [authRoleMap['Admin'], authRoleMap['AdvancedUser']]
    },
    // 答疑管理
    questionList: {
        menuShow: [authRoleMap['Admin'], authRoleMap['AdvancedUser']]
    },
    // 家长管理
    parentList: {
        menuShow: [authRoleMap['Admin'], authRoleMap['AdvancedUser']]
    },
    // 孩子管理
    childList: {
        menuShow: [authRoleMap['Admin'], authRoleMap['AdvancedUser']]
    },
    // 待匹配列表
    matchList: {
        menuShow: [authRoleMap['Admin'], authRoleMap['AdvancedUser']]
    },
    // 教师管理
    teachers: {
        menuShow: [authRoleMap['Admin'], authRoleMap['AdvancedUser']]
    },
    // 培训管理
    works: {
        menuShow: [authRoleMap['Admin'], authRoleMap['AdvancedUser']]
    },
    professor: {
        menuShow: [authRoleMap['Admin']]
    },
    user: {
        menuShow: [authRoleMap['Admin']]
    }
}

export const genderTypeList = [
    {
        key: 'male',
        display: '男'
    },
    {
        key: 'female',
        display: '女'
    },
    {
        key: 'unknown',
        display: '未知'
    }
]

export const genderTypeMap = {
    'male': {
        key: 'male',
        display: '男'
    },
    'female': {
        key: 'female',
        display: '女'
    },
    'unknown': {
        key: 'unknown',
        display: '未知'
    }
}

export const showStatusList = [
    {
        key: 0,
        display: '私密'
    },
    {
        key: 1,
        display: '公开'
    }
]

export const showStatusMap = {
    0: {
        key: 0,
        display: '私密',
        className: 'y-color-R1'
    },
    1: {
        key: 1,
        display: '公开',
        className: 'y-color-B1'
    }
}

export const matchStatusList = [
    {
        key: 0,
        display: '未匹配'
    },
    {
        key: 1,
        display: '已匹配'
    }
]

export const matchStatusMap = {
    0: {
        key: 0,
        display: '未匹配',
        className: 'y-color-R1'
    },
    1: {
        key: 1,
        display: '已匹配',
        className: 'y-color-B1'
    }
}


export const constellationList = [
    {
        key: 'Capricorn',
        display: '摩羯座'
    },
    {
        key: 'Aquarius',
        display: '水瓶座'
    },
    {
        key: 'Pisces',
        display: '双鱼座'
    },
    {
        key: 'Aries',
        display: '白羊座'
    },
    {
        key: 'Taurus',
        display: '金牛座'
    },
    {
        key: 'Gemini',
        display: '双子座'
    },
    {
        key: 'Cancer',
        display: '巨蟹座'
    },
    {
        key: 'Leo',
        display: '狮子座'
    },
    {
        key: 'Virgo',
        display: '处女座'
    },
    {
        key: 'Libra',
        display: '天秤座'
    },
    {
        key: 'Scorpio',
        display: '天蝎座'
    },
    {
        key: 'Sagittarius',
        display: '射手座'
    }
]

export const constellationMap = {
    'Capricorn': {
        key: 'Capricorn',
        display: '摩羯座'
    },
    'Aquarius': {
        key: 'Aquarius',
        display: '水瓶座'
    },
    'Pisces': {
        key: 'Pisces',
        display: '双鱼座'
    },
    'Aries': {
        key: 'Aries',
        display: '白羊座'
    },
    'Taurus': {
        key: 'Taurus',
        display: '金牛座'
    },
    'Gemini': {
        key: 'Gemini',
        display: '双子座'
    },
    'Cancer': {
        key: 'Cancer',
        display: '巨蟹座'
    },
    'Leo': {
        key: 'Leo',
        display: '狮子座'
    },
    'Virgo': {
        key: 'Virgo',
        display: '处女座'
    },
    'Libra': {
        key: 'Libra',
        display: '天秤座'
    },
    'Scorpio': {
        key: 'Scorpio',
        display: '天蝎座'
    },
    'Sagittarius': {
        key: 'Sagittarius',
        display: '射手座'
    },
    'unknown': {
        key: 'unknown',
        display: '未知'
    },
}

// 学历列表
export const degreeList = [
    {
        key: 'primary_school',
        display: '小学'
    },
    {
        key: 'junior_high_school',
        display: '初中'
    },
    {
        key: 'senior_high_school',
        display: '高中'
    },
    {
        key: 'junior_college',
        display: '大专'
    },
    {
        key: 'undergraduate',
        display: '本科'
    },
    {
        key: 'graduate',
        display: '研究生'
    },
    {
        key: 'doctor',
        display: '博士'
    }
];

// 学历映射
export const degreeMap = {
    'primary_school': {
        key: 'primary_school',
        display: '小学'
    },
    'junior_high_school': {
        key: 'junior_high_school',
        display: '初中'
    },
    'senior_high_school': {
        key: 'senior_high_school',
        display: '高中'
    },
    'junior_college': {
        key: 'junior_college',
        display: '大专'
    },
    'undergraduate': {
        key: 'undergraduate',
        display: '本科'
    },
    'graduate': {
        key: 'graduate',
        display: '研究生'
    },
    'doctor': {
        key: 'doctor',
        display: '博士'
    },
    'unknown': {
        key: 'unknown',
        display: '未知'
    },
};


export const relationStates = {
    recommended: 'recommended', // 推荐
    unmatched: 'unmatched', // 不合适
    interviewed: 'interviewed', // 面试
    passed: 'passed', // 面试通过
    eliminated: 'eliminated', // 淘汰
    probationary: 'probationary', // 试用
    rejected_probationary: 'rejected_probationary', // 拒绝试用
    extended_probationary: 'extended_probationary', // 延长试用
    rejected_extended_probationary: 'rejected_extended_probationary', // 拒绝延长试用
    unemployed: 'unemployed', // 未转正
    employed: 'employed', // 转正
    rejected_employed: 'rejected_employed', // 拒绝转正
    terminated: 'terminated', // 解除
    unknown: 'unknown' // 未知
}

// 关系状态列表

export const relationStateList = [
    {
        key: relationStates.recommended,
        display: '推荐',
        className: 'y-color-O1'
    },
    {
        key: relationStates.unmatched,
        display: '不合适',
        className: 'y-color-O2'
    },
    {
        key: relationStates.interviewed,
        display: '面试',
        className: 'y-color-R1'
    },
    {
        key: relationStates.passed,
        display: '面试通过',
        className: 'y-color-R2'
    },
    {
        key: relationStates.eliminated,
        display: '淘汰',
        className: 'y-color-N3'
    },
    {
        key: relationStates.probationary,
        display: '试用',
        className: 'y-color-B1'
    },
    {
        key: relationStates.rejected_probationary,
        display: '拒绝试用',
        className: 'y-color-B2'
    },
    {
        key: relationStates.extended_probationary,
        display: '延期试用',
        className: 'y-color-B1'
    },
    {
        key: relationStates.rejected_extended_probationary,
        display: '拒绝延期试用',
        className: 'y-color-B2'
    },
    {
        key: relationStates.unemployed,
        display: '未转正',
        className: 'y-color-G1'
    },
    {
        key: relationStates.employed,
        display: '转正',
        className: 'y-color-G2'
    },
    {
        key: relationStates.rejected_employed,
        display: '拒绝转正',
        className: 'y-color-R2'
    },
    {
        key: relationStates.terminated,
        display: '解除',
        className: 'y-color-N0'
    }
];

// 关系状态映射
export const relationStateMap = relationStateList.reduce((acc, cur) => {
    if (!acc[cur.key]) {
        acc[cur.key] = {};
    }
    acc[cur.key]['key'] = cur.key;
    acc[cur.key]['display'] = cur.display;
    acc[cur.key]['className'] = cur.className;
    return acc;
}, {});

export function formattedRelationState(state) {
    return relationStateMap[state]?.display ?? '未知';
}
export function formattedRelationClassName(state) {
    return relationStateMap[state]?.className ?? '';
}


export const notificationTypeList = [
    {
        key: 'relation',
        display: '匹配',
        className: 'y-color-O1'
    },
    {
        key: 'question',
        display: '问答',
        className: 'y-color-B1'
    },
]
export const notificationTypeMap = notificationTypeList.reduce((acc, cur) => {
    if (!acc[cur.key]) {
        acc[cur.key] = {};
    }
    acc[cur.key]['key'] = cur.key;
    acc[cur.key]['display'] = cur.display;
    acc[cur.key]['className'] = cur.className;
    return acc;
}, {});
export function formattedNotificationType(type) {
    return notificationTypeMap[type]?.display ?? '未知';
}
export function formattedNotificationClassName(type) {
    return notificationTypeMap[type]?.className ?? '';
}

export const notificationStatusList = [
    {
        key: 0,
        display: '未读',
        className: 'y-color-O1'
    },
    {
        key: 1,
        display: '已读',
        className: 'y-color-B1'
    }
]
export const notificationStatusMap = notificationStatusList.reduce((acc, cur) => {
    if (!acc[cur.key]) {
        acc[cur.key] = {};
    }
    acc[cur.key]['key'] = cur.key;
    acc[cur.key]['display'] = cur.display;
    acc[cur.key]['className'] = cur.className;
    return acc;
}, {});

export function formattedNotificationStatus(status) {
    return notificationStatusMap[status]?.display ?? '未知';
}
export function formattedNotificationStatusClassName(status) {
    return notificationStatusMap[status]?.className ?? '';
}

export const userRoleList = [
    {
        key: 'admin',
        display: '管理员'
    },
    {
        key: 'expert',
        display: '专家'
    },
    {
        key: 'teacher',
        display: '老师'
    },
    {
        key: 'parent',
        display: '家长'
    },
    {
        key: 'unknown',
        display: '未设置'
    }
]

export const userRoleNormal = [
    {
        key: 'teacher',
        display: '老师'
    },
    {
        key: 'parent',
        display: '家长'
    }
]

export const userRoleMap = userRoleList.reduce((acc, cur) => {
    if (!acc[cur.key]) {
        acc[cur.key] = {};
    }
    acc[cur.key]['key'] = cur.key;
    acc[cur.key]['display'] = cur.display;
    return acc;
}, {})

export function formattedUserRole(role) {
    return userRoleMap[role]?.display ?? '未知';
}


